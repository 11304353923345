<template lang="pug">
  v-container.col-md-12.col-12
    v-dialog(v-if="dialogPatient" fullscreen="" v-model='dialogPatient' width='800')
      v-card
        patient(:seeData="false" @finishCreate="finishCreate" :selectedPatient="selectedPatient" :idPatient="idPatient" @exitPatient="dialogPatient = false")
    v-dialog(v-model='confirmationDialog' persistent='' max-width='290')
      v-card
        v-card-title.headline
          | Confirmar
        v-card-text &iquest;Estás seguro de eliminar este tratamiento?
        v-card-actions
          v-spacer
            v-btn(color='red darken-1' text='' @click='confirmationDialog = false')
              | Cancelar
            v-btn(color='blue darken-1' @click='acceptConfirmation')
              | Aceptar
    v-snackbar(v-model='snackbar.show' :timeout='30000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(v-model='snackbar.show' :timeout='30000' top='' right='' :color='snackbar.color')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' @click.native='snackbar.show = false' icon='')
    //-     v-icon mdi-close
    v-row
      v-col(cols="12" md="5")
        base-material-card.px-5.py-3(icon='mdi-clipboard-text' title='Lista de Anamnesis')
          v-container#patient-table(fluid='' tag='section')
            v-row
              v-col(cols="1" md="1" style="right: 50px; position: absolute; top: 20px;")
                v-tooltip(bottom='')
                  template(v-slot:activator='{ attrs, on }')
                    v-btn(v-on="on" icon='' color='secondary' @click="downLoadExcelFile")
                      v-icon(style="font-size: 30px;") mdi-arrow-down-bold-box
                  span Descargar Lista de Anamnesis
              v-col(cols="1" md="1" v-if="patientSelected == null" style="right: 90px; position: absolute; top: 20px;")
                v-tooltip(bottom='')
                  template(v-slot:activator='{ attrs, on }')
                    v-btn(v-on="on" icon='' color='primary' @click="newPatient")
                      v-icon(style="font-size: 30px;") mdi-account-plus
                  span Agregar Nuevo Paciente
              v-col(cols="1" md="1" v-if="patientSelected == null" style="right: 130px; position: absolute; top: 20px;")
                v-tooltip(bottom='')
                  template(v-slot:activator='{ attrs, on }')
                    v-btn(v-on="on" icon='' color='orange' @click="viewPatient")
                      v-icon(style="font-size: 30px;") mdi-eye
                  span Ver datos del paciente
              v-col(cols="12" md="12" style="padding: 0px; margin: 0px;" v-if="patientSelected == null" )
                v-autocomplete(@change="changePatient" v-model='valuePatient' :items='patientLstFilter' return-object item-text="data.data.completeData"  label='Pacientes')
                  template(v-slot:no-data='')
                    v-list-item
                      v-list-item-title
                        | Aún no hay pacientes, regístralos desde el menú
                        strong  Pacientes
              v-col(cols="12" md="12")
                v-progress-linear(indeterminate='' color='primary' v-if="progressBar")
                v-simple-table
                  thead
                    tr
                      th.primary--text
                        | Paciente
                      th.primary--text
                        | Fecha
                      th.text-center.primary--text
                        | Eliminar
                  tbody
                    tr(v-for="(antecedente, index) in arrAntecedentesLst" :key="index")
                      td(@click="selectAntecedente(antecedente)" style="cursor: pointer;") {{antecedente.data.data.patientCompletData.toString().split(' | ')[0]}}
                      td(@click="selectAntecedente(antecedente)" style="cursor: pointer;") {{antecedente.data.data.currentDate}}
                      td(style="cursor: pointer;").text-center
                        v-btn(icon='' color='red' @click="deleteAntecedente(antecedente)")
                          v-icon mdi-delete
        v-row
          v-col(cols="6" md="6").text-center
            v-tooltip(bottom='')
              template(v-slot:activator='{ attrs, on }')
                v-btn(v-on="on" icon='' color='secondary' @click="cleanData")
                  v-icon(style="font-size: 40px;") mdi-clipboard-plus
              span Nuevo Registro
          v-col(cols="6" md="6")
            v-tooltip(bottom='')
              template(v-slot:activator='{ attrs, on }')
                v-btn(v-on="on" icon='' color='primary' @click="saveHistory" :loading="loadingSaveData")
                  v-icon(style="font-size: 40px;") mdi-content-save
              span Guardar Datos
      v-col(cols="12" md="7")
        v-card.v-card--material.pa-3.v-card.v-sheet.theme--light.v-card--material--has-heading
          v-row.text-start.v-card--material__heading.mb-n6.v-sheet.theme--dark.elevation-6.primary.pa-7(justify='center' no-gutters='')
            | Anamnesis
          v-row
            v-col(cols='12' md='4')
              v-select(v-model='modelpersonal' return-object :items='personalHistoryLst' item-text='id' label='Antecedentes Personales' hide-details='' single-line='')
            v-col(cols='12' md='8')
              v-combobox(v-model='historyObject.modelPersonalLst' :items='modelpersonal.data' item-text="data" hide-selected='' label='Antecedentes Personales - Seleccione' multiple='' small-chips='')
                //- <!--template(v-slot:no-data='')-->
                v-list-item
                  v-list-item-content
                    v-list-item-title
                      | Lo sentimos, no hemos encontrado resultados similares
          v-row
            v-col(cols='12' md='4')
              v-select(v-model='modelfamiliar' return-object :items='familyHistoryLst' item-text='id' label='Antecedentes Familiares' hide-details='' single-line='')
            v-col(cols='12' md='8')
              v-combobox(v-model='historyObject.modelfamiliarLst' :items='modelfamiliar.data' item-text="data" hide-selected='' label='Antecedentes Familiares - Seleccione' multiple='' small-chips='')
                //- <!--template(v-slot:no-data='')-->
                v-list-item
                  v-list-item-content
                    v-list-item-title
                      | No hay resultados encontrados similares.
          //- <!--v-checkbox(v-model='checkbox1' :label='`Hábitos de higiene bucal`')-->
          v-row
            v-col(cols="12" md="8")
              v-textarea(label='Observaciones' height="32px" v-model="historyObject.obs")
            v-col(cols="12" md="4")
              v-text-field(label='Fecha de Registro' v-model='historyObject.currentDate' disabled)
          v-row
            v-col(cols="6" style="padding-top: 20px;")
              h3(style="color: primary;") Hábitos de higiene bucal
            v-col(cols="6")
              v-checkbox(v-model='historyObject.generateAlert' label='Generar Alerta de Anamnesis' style='align-items:center;')
          v-row(style="padding: 20px;")
            v-col(cols="12" style="padding: 0px; margin: 0px;")
              v-row(style="width: 100%;")
                v-col(cols="10" style="padding: 0px; margin: 0px;")
                  p Cepillado dental (3 veces al día)
                v-col(cols="2" style="padding: 0px; margin: 0px;")
                  v-switch(style="margin-top: 0px;" v-model='historyObject.oralHygieneHabits.cepilladoDental' :label='`${historyObject.oralHygieneHabits.cepilladoDental === true ? "SI" : "NO"}`')
            v-col(cols="12" style="padding: 0px; margin: 0px;")
              v-row(style="width: 100%;")
                v-col(cols="10" style="padding: 0px; margin: 0px;")
                  p Usa hilo dental
                v-col(cols="2" style="padding: 0px; margin: 0px;")
                  v-switch(style="margin-top: 0px;" v-model='historyObject.oralHygieneHabits.hiloDental' :label='`${historyObject.oralHygieneHabits.hiloDental === true ? "SI" : "NO"}`')
            v-col(cols="12" style="padding: 0px; margin: 0px;")
              v-row(style="width: 100%;")
                v-col(cols="10" style="padding: 0px; margin: 0px;")
                  p Usa enjuague bucal
                v-col(cols="2" style="padding: 0px; margin: 0px;")
                  v-switch(style="margin-top: 0px;" v-model='historyObject.oralHygieneHabits.enjuagueBucal' :label='`${historyObject.oralHygieneHabits.enjuagueBucal === true ? "SI" : "NO"}`')
            v-col(cols="12" style="padding: 0px; margin: 0px;")
              v-row(style="width: 100%;")
                v-col(cols="10" style="padding: 0px; margin: 0px;")
                  p Si tiene aparatos intraorales ¿los higieniza? (prótesis parciales, totales, placas ortopédicas, brackets)
                v-col(cols="2" style="padding: 0px; margin: 0px;")
                  v-switch(style="margin-top: 0px;" v-model='historyObject.oralHygieneHabits.aparatosIntrahorales' :label='`${historyObject.oralHygieneHabits.aparatosIntrahorales === true ? "SI" : "NO"}`')
</template>
<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/auth'
  import 'firebase/database'
  import * as functions from '../../../functions.js'
  export default {
    components: {
      Patient: () => import('@/views/dashboard/component/patient/PatientProfile'),
    },
    props: {
      patientSelected: null,
      idPatient: null,
    },
    data: () => ({
      loadingSaveData: false,
      selectedPatient: {},
      confirmationDialog: false,
      antecedenteSelected: {},
      progressBar: false,
      patientID: '',
      antecedenteID: '',
      arrAntecedentesLst: [],
      arrAntecedentesLstComplete: [],
      valuePatient: null,
      historyObject: {
        generateAlert: false,
        patientCompletData: '',
        currentDate: '',
        obs: '',
        oralHygieneHabits: {
          cepilladoDental: false,
          hiloDental: false,
          enjuagueBucal: false,
          aparatosIntrahorales: false,
        },
        modelfamiliarLst: [],
        modelPersonalLst: [],
        arrPreguntasDoctor: [],
      },
      snackbar: {
        show: false,
        text: '',
        color: '',
        place: '',
      },
      dialogPatient: false,
      patientLstFilter: [],
      searchTable: '',
      itemspersonal: ['Anemia', 'Cáncer', 'Diabetes', 'Asma', 'Obesidad', 'Hepatitis', 'VIH', 'Anorexia o trastornos alimenticios', 'Depresión', 'Hipertensión', 'Migrañas', 'Alergias'],
      modelpersonal: '',
      itemsfamiliar: ['Anemia', 'Cáncer', 'Diabetes', 'Asma', 'Obesidad', 'Hepatitis', 'VIH', 'Anorexia o trastornos alimenticios', 'Depresión', 'Hipertensión', 'Migrañas', 'Alergias'],
      modelfamiliar: '',
      checkbox1: false,
      search: null,
    }),
    computed: {
      patientLst () {
        return this.$store.state.patients.pacientes
      },
      personalHistoryLst () {
        return this.$store.state.general.personalhistory
      },
      familyHistoryLst () {
        return this.$store.state.general.familyhistory
      },
    },
    watch: {
      model (val) {
        if (val.length > 5) {
          this.$nextTick(() => this.model.pop())
        }
      },
      patientLst () {
        this.patientLstFilter = this.patientLst
      },
    },
    created () {
      this.$store.dispatch('getCurrentDay').then(res => {
        this.historyObject.currentDate = res
      })
      this.patientLstFilter = this.$store.state.patients.pacientes
      if (this.patientSelected != null) {
        this.valuePatient = {
          data: {
            data: this.patientSelected,
          },
          id: this.idPatient,
        }
      } else {
        if (this.patientLstFilter) {
          this.valuePatient = this.patientLstFilter[0]
          if (this.valuePatient) {
            this.selectedPatient = this.valuePatient.data.data
          }
        }
      }
      this.getDataAntecedentes()

      // if (!this.patientLstFilter) {
      //   this.newPatient()
      // }
    },
    mounted () {
      // if (this.patientLstFilter === null) {
      //   this.newPatient()
      // }
    },
    methods: {
      concatenateStrings (arreglo) {
        let concatenatedString = ''
        for (let j = 0; j < arreglo.length; j++) {
          if (j === 0) {
            concatenatedString = concatenatedString.concat(arreglo[j].data)
          } else {
            concatenatedString = concatenatedString + ', ' + arreglo[j].data
          }
        }
        return concatenatedString
      },
      downLoadExcelFile () {
        let modelPersonalLstString = ''
        let modelFamiliarLstString = ''
        const data = []
        if (this.arrAntecedentesLstComplete) {
          for (let i = 0; i < this.arrAntecedentesLstComplete.length; i++) {
            modelFamiliarLstString = this.concatenateStrings(this.arrAntecedentesLstComplete[i].data.data.modelfamiliarLst)
            modelPersonalLstString = this.concatenateStrings(this.arrAntecedentesLstComplete[i].data.data.modelPersonalLst)
            data.push({
              Fecha: this.arrAntecedentesLstComplete[i].data.data.currentDate,
              'Antecedentes personales': modelPersonalLstString,
              'Antecedentes familiares': modelFamiliarLstString,
              Observaciones: this.arrAntecedentesLstComplete[i].data.data.obs,
              'Datos del paciente': this.arrAntecedentesLstComplete[i].data.data.patientCompletData,
              'Aparatos intraorales': this.arrAntecedentesLstComplete[i].data.data.oralHygieneHabits.aparatosIntrahorales ? 'SI' : 'NO',
              'Cepillado Dental': this.arrAntecedentesLstComplete[i].data.data.oralHygieneHabits.cepilladoDental ? 'SI' : 'NO',
              'Enjuague bucal': this.arrAntecedentesLstComplete[i].data.data.oralHygieneHabits.enjuagueBucal ? 'SI' : 'NO',
              'Hilo dental': this.arrAntecedentesLstComplete[i].data.data.oralHygieneHabits.hiloDental ? 'SI' : 'NO',
            })
          }
          functions.JSONToCSVConvertor(data, 'Antecedentes', true)
        }
      },
      newPatient () {
        this.selectedPatient = null
        this.dialogPatient = true
      },
      viewPatient () {
        if (this.valuePatient) {
          this.idPatient = this.valuePatient.id
          this.selectedPatient = this.valuePatient.data.data
          this.dialogPatient = true
        }
      },
      setQuestion (index) {
        this.historyObject.arrPreguntasDoctor[index].pregunta = document.getElementById('question_' + index).value
      },
      setResponse (index) {
        this.historyObject.arrPreguntasDoctor[index].respuesta = document.getElementById('respuesta_' + index).value
      },
      deleteQuestion () {
        this.historyObject.arrPreguntasDoctor.splice((this.historyObject.arrPreguntasDoctor.length - 1), 1)
      },
      addQuestion () {
        this.historyObject.arrPreguntasDoctor.push({
          pregunta: '',
          respuesta: '',
        })
      },
      selectAntecedente (antecedente) {
        this.historyObject = antecedente.data.data
        this.patientID = antecedente.idPatient
        this.antecedenteID = antecedente.id
      },
      deleteAntecedente (antecedente) {
        this.confirmationDialog = true
        this.antecedenteSelected = antecedente
      },
      acceptConfirmation () {
        const currentUser = firebase.auth().currentUser
        const user = this.$store.state.users.user
        firebase.database().ref(`/${user.selectAccount}/antecedentes/${currentUser.uid}/${this.antecedenteSelected.idPatient}/${this.antecedenteSelected.id}/`).remove().then(() => {
          this.getDataAntecedentes()
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Antecedente eliminado de manera correcta',
          }
          this.cleanData()
          this.confirmationDialog = false
        })
      },
      changePatient () {
        this.selectedPatient = this.valuePatient.data.data
        this.arrAntecedentesLst = this.arrAntecedentesLstComplete.filter(item => {
          return (item.data.data.patientCompletData === this.valuePatient.data.data.completeData)
        })
      },
      saveHistory () {
        this.loadingSaveData = true
        const currentUser = firebase.auth().currentUser
        this.$store.dispatch('getCurrentDay').then(res => {
          this.historyObject.currentDate = res
          if (this.patientID === '' && this.antecedenteID === '') {
            if (this.valuePatient) {
              this.historyObject.patientCompletData = this.valuePatient.data.data.completeData
              const user = this.$store.state.users.user
              firebase.database().ref(`/${user.selectAccount}/antecedentes/${currentUser.uid}/${this.valuePatient.id}/`).push(
                {
                  data: this.historyObject,
                }).then((res) => {
                this.getDataAntecedentes()
                this.loadingSaveData = false
                this.snackbar = {
                  show: true,
                  color: 'green',
                  text: 'Antecedente guardado correctamente',
                }
                this.cleanData()
              }).catch(() => {
                this.loadingSaveData = false
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Error al guardar el antecedente',
                }
              })
            } else {
              this.loadingSaveData = false
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Debes seleccionar el paciente para continuar',
              }
            }
          } else {
            const user = this.$store.state.users.user
            firebase.database().ref(`/${user.selectAccount}/antecedentes/${currentUser.uid}/${this.patientID}/${this.antecedenteID}/data/`).set(
              this.historyObject).then((res) => {
              this.getDataAntecedentes()
              this.snackbar = {
                show: true,
                color: 'green',
                text: 'Antecedente guardado correctamente',
              }
              this.loadingSaveData = false
              this.cleanData()
            })
          }
        })
      },
      cleanData () {
        this.historyObject = {
          currentDate: '',
          obs: '',
          oralHygieneHabits: {
            cepilladoDental: false,
            hiloDental: false,
            enjuagueBucal: false,
            aparatosIntrahorales: false,
          },
          modelfamiliarLst: [],
          modelPersonalLst: [],
          arrPreguntasDoctor: [],
        }
        this.patientID = ''
        this.antecedenteID = ''
      },
      getDataAntecedentes () {
        this.progressBar = true
        this.arrAntecedentesLst = []
        const currentUser = firebase.auth().currentUser
        const user = this.$store.state.users.user
        firebase.database().ref(`/${user.selectAccount}/antecedentes/${currentUser.uid}/`).once('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                return { id: cou, data: Object.keys(res[cou]).map(item => { return { id: item, data: res[cou][item] } }) }
              })
              for (let i = 0; i < arr.length; i++) {
                for (let j = 0; j < arr[i].data.length; j++) {
                  arr[i].data[j].idPatient = arr[i].id
                  if (!arr[i].data[j].data.data.arrPreguntasDoctor) {
                    arr[i].data[j].data.data.arrPreguntasDoctor = []
                  }
                  this.arrAntecedentesLst.push(arr[i].data[j])
                }
              }
              this.arrAntecedentesLstComplete = this.arrAntecedentesLst
              this.changePatient()
            }
            this.progressBar = false
          }
        })
      },
      finishCreate () {
        this.dialogPatient = false
      },
    },
  }
</script>
<style lang='scss'>
  .v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip):not(.v-avatar){
    font-family: 'ceraProMedium';
  }
</style>
